import { Grid, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { inject } from 'mobx-react';

import React from 'react';
import AppController from '../../../base/App.controller';
import { BNPP, TOKEN } from '../../../constants/banks';
import checkIcon from '../../../images/icon-check.svg';
import IconWrapper from '../wrappers/IconWrapper';
import LinkWrapper from '../wrappers/LinkWrapper';
import { IInfoComp } from '../types';
import { Itranslation } from 'types';

const { publicRuntimeConfig } = require('next/config').default();

const styles: any = {
    root: {
        backgroundColor: 'var(--ghost-white)',
        flex: '1',
        flexDirection: 'column',
        alignItems: 'flex-start',
        display: 'flex',
        color: 'var(--milled-wine)',
        fontSize: 'var(--fs-large)',
        padding: '1rem',
    },
    container: {
        maxWidth: '550px',
        marginTop: '40px',
    },
    title: {
        fontSize: 'var(--fs-xx-large)',
        lineHeight: '45px',
        paddingTop: '18%',
        paddingBottom: '10%',
    },
    bodyText: {
        fontSize: 'var(--fs-large)',
        lineHeight: '34px',
        marginBottom: '10%',
        fontWeight: 'var(--font-weight-regular)',
    },
    normalText: {
        fontWeight: 'var(--font-weight-regular)',
    },
    checkWrapper: {
        textAlign: 'center',
    },
    bottomSection: {
        marginTop: '10%',
    },
};

const Info: React.FC<IInfoComp> = ({ AppStore, hideContent = false, page, classes }) => {
    const scope = AppStore?.scope;
    const namespaces = ['signup', 'common'];
    if (page) {
        namespaces.push(page);
    }
    const { t }: Itranslation = AppController.getTranslation(scope, namespaces);
    const resellerTypes = Object.keys(publicRuntimeConfig.resellers).map((reseller) => {
        return reseller;
    });

    const disabledEnvs = ['sandbox', 'prd'];

    const disableReseller = [BNPP];

    return (
        <div className={clsx(classes.root, 'Info')}>
            {!hideContent &&
                !disableReseller.includes(scope as string) &&
                (scope !== TOKEN || !disabledEnvs.includes(publicRuntimeConfig.tokenEnv)) && (
                    <Grid container className={classes.container}>
                        <Grid item container xs={12} justify="flex-end">
                            <span className={classes.normalText}>
                                {page &&
                                [
                                    'signin',
                                    'forgot-password',
                                    'check-email',
                                    'reset-password',
                                ].includes(page)
                                    ? !resellerTypes.includes(scope as string) &&
                                      t('common:needAnAccount')
                                    : t('common:alreadyHaveAnAccount')}
                                &nbsp;
                            </span>
                            {page &&
                            ['signin', 'forgot-password', 'check-email', 'reset-password'].includes(
                                page,
                            ) ? (
                                !resellerTypes.includes(scope as string) && (
                                    <LinkWrapper href="/signup" className="link">
                                        {t('common:signUp')}
                                    </LinkWrapper>
                                )
                            ) : (
                                <LinkWrapper href="/signin" className="link">
                                    {t('common:signIn')}
                                </LinkWrapper>
                            )}
                        </Grid>

                        <Grid item xs={2} />
                        <Grid item container xs={10}>
                            <Grid item xs={12}>
                                <h1 className={classes.title}>{t('freeAccountsGiveAccess')}</h1>
                            </Grid>
                            {['accessBankDataAPIs', 'integrateLowCost'].map((key, index) => (
                                <Grid item xs={12} container key={index}>
                                    <Grid item xs={2} className={classes.checkWrapper}>
                                        <IconWrapper icon={checkIcon} disabled={false} stroke />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <div className={classes.bodyText}>{t(key)}</div>
                                    </Grid>
                                </Grid>
                            ))}
                            <Grid item xs={12} className={classes.bottomSection}>
                                {t('questionsContactSales1')}
                                <LinkWrapper
                                    className={'link'}
                                    target="_blank"
                                    rel="noopener"
                                    href={publicRuntimeConfig.externalLinks.support.centre}>
                                    {t('questionsContactSales2')}
                                </LinkWrapper>
                                .
                            </Grid>
                        </Grid>
                    </Grid>
                )}
        </div>
    );
};

export default withStyles(styles, { name: 'Info' })(inject('AppStore')(Info));
