import { action, computed, flow, configure, observable } from 'mobx';
import { OFF, ON } from '../../../constants/inputField';
import { INVALID } from '../../../constants/memberTypes';
import _get from 'lodash/get';
// types
import { Translation } from 'types';
import { IPasswordFieldStoreValues } from '../types';

configure({
    enforceActions: 'observed',
});

class PasswordFieldStore {
    constructor(defaultValues?: object) {
        this.testForPasswordNotInBreechedList = _get(
            defaultValues,
            'testForPasswordNotInBreechedList',
        );
        this.isMatch = _get(defaultValues, 'reEnterPasswordMatch');
    }

    validationConditions = ['atLeast8', 'oneNumber', 'oneCapitalLetter', 'oneSpecialCharacter'];

    isMatch: boolean | undefined;

    @observable
    values: IPasswordFieldStoreValues = {
        showPassword: false,
        showReEnteredPassword: false,
        atLeast8: OFF,
        oneNumber: OFF,
        oneCapitalLetter: OFF,
        oneSpecialCharacter: OFF,
        passwordNotInBreechedList: OFF,
        isLoading: false,
    };

    @observable
    password = '';

    @observable
    reEnteredPassword = '';

    @observable
    testForPasswordNotInBreechedList: boolean | undefined = false;

    @action
    handleChangePassword = (value: string) => {
        this.password = value;
    };

    @action
    handleChangeReEnteredPassword = (value: string) => {
        this.reEnteredPassword = value;
    };

    @action
    setValues = (password: string) => {
        this.values = {
            ...this.values,
            ...this.validatePassword(password),
        };
    };

    @action
    setIsLoading = (value: boolean) => {
        this.values = {
            ...this.values,
            isLoading: value,
        };
    };

    @action
    toggleShowPassword = () => {
        this.values.showPassword = !this.values.showPassword;
    };

    @action
    checkIfPasswordsMatch = (t: Translation) => {
        if (this.password !== this.reEnteredPassword) {
            return t('passwordsNotMatched');
        }
    };

    @action
    toggleReEnteredShowPassword = () => {
        this.values.showReEnteredPassword = !this.values.showReEnteredPassword;
    };

    @action
    setPasswordNotInBreechedList = (passwordValid: boolean) => {
        this.values = {
            ...this.values,
            passwordNotInBreechedList: passwordValid ? ON : OFF,
        };
    };

    validatePassword = (password: string) => {
        const { passwordNotInBreechedList } = this.values;
        const atLeast8 = password.length >= 8 ? ON : OFF,
            oneNumber = /\d/.test(password) ? ON : OFF,
            oneCapitalLetter = /[A-Z]/.test(password) ? ON : OFF,
            oneSpecialCharacter = this.testPassword(password);
        const passwordIsEmpty = password.length === 0;

        return {
            atLeast8,
            oneNumber,
            oneCapitalLetter,
            oneSpecialCharacter,
            passwordNotInBreechedList: passwordIsEmpty ? OFF : passwordNotInBreechedList,
        };
    };

    /* istanbul ignore next */
    calculateScore = (password: string | undefined) => {
        let score = 0;
        const { passwordNotInBreechedList } = this.values;
        if (password && password.length > 4) {
            if (password.length > 10) {
                score = score + 1;
            }
            if (/[A-Z]/.test(password)) {
                score = score + 1;
            }
            if (/\d/.test(password)) {
                score = score + 1;
            }
            if (this.testPassword(password) === ON) {
                score = score + 1;
            }
            if (passwordNotInBreechedList === OFF) {
                return 1;
            }
        }
        return score;
    };

    testPassword = (str: string) => {
        const hasOneSpecialChar = /[~`!_.@#$%^&*()+=\-[\]\\';,/{}|\\":<>?]/g.test(str);
        const hasInvalidChar = /[^0-9a-zA-Z~`!_.@#$%^&*()+=\-[\]\\';,/{}|\\":<>?]/g.test(str);

        if (hasInvalidChar) {
            return INVALID;
        } else {
            if (hasOneSpecialChar) {
                return ON;
            } else return OFF;
        }
    };

    isPasswordValid = flow(
        function* (this: PasswordFieldStore): Generator<any, boolean | Promise<any>, any> {
            if (this.isLoading) {
                yield new Promise((resolve) => setTimeout(resolve, 300));
                return this.isPasswordValid();
            }

            return this.isValid;
        }.bind(this),
    );

    @computed
    get isValid() {
        const isPasswordNotInBreechedList = this.values.passwordNotInBreechedList === ON;
        const testForPasswordNotInBreechedList = this.testForPasswordNotInBreechedList
            ? isPasswordNotInBreechedList
            : true;

        let valid =
            this.values.atLeast8 === ON &&
            this.values.oneNumber === ON &&
            this.values.oneCapitalLetter === ON &&
            this.values.oneSpecialCharacter === ON &&
            testForPasswordNotInBreechedList;

        if (this.isMatch) {
            valid = valid && this.password === this.reEnteredPassword;
        }

        return valid;
    }

    @computed
    get isLoading() {
        return this.values.isLoading;
    }
}

export default PasswordFieldStore;
