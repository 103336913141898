import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import InlineSVG from 'react-inlinesvg';
//types
import { IIconWrapperProps as Props } from '../types';

const styles = () => ({
    root: {},
    stroke: {
        stroke: 'var(--primary)',
    },
    fill: {
        fill: 'var(--primary)',
    },
    'stroke-disabled': {
        stroke: 'var(--gainsboro)',
    },
    'fill-disabled': {
        fill: 'var(--gainsboro)',
    },
});

const IconWrapper: React.FC<Props> = ({ icon, classes, stroke, fill, disabled, ...props }) => {
    return (
        <InlineSVG
            className={clsx(classes.root, {
                [classes[`stroke${disabled ? '-disabled' : ''}`]]: Boolean(stroke),
                [classes[`fill${disabled ? '-disabled' : ''}`]]: Boolean(fill),
            })}
            src={icon}
            {...props}
        />
    );
};

export default withStyles(styles, { name: 'IconWrapper' })(IconWrapper);
